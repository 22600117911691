.blockquote {
	font-family: var(--austin);
	margin: var(--spacing-xxs) 0;
	max-width: var(--line-length-max);
	text-align: center;

	&::before {
		content: open-quote;
	}

	&::after {
		content: close-quote;
	}

	&::before,
	&::after {
		display: inline-block;
		margin: 0.2em;
		transform: scale(1.5);
	}
}

/*
	Julie and I (joseph) are working through new RTE heading rules
	There will be some updates soon 
*/

.h1,
.h2,
.h3 {
	margin-bottom: var(--spacing-micro);
}

.h4 {
	margin-bottom: 4px;
}
